
////192.168.130.93
const BASE_URL="https://democlkapi.cliker.mx/api/";
const ENVIROMENT_PARAMETROS={ url : "democlkapi.cliker.mx", tls: false };
const URL_IMAGEN_BASE_URL="https://democlkapi.cliker.mx/";

////192.168.130.91
//const BASE_URL="https://apidevmid19.pbmg.com.mx/api/";
//const ENVIROMENT_PARAMETROS={ url : "apidevmid19.pbmg.com.mx", tls: true };
//const URL_IMAGEN_BASE_URL="https://apidevmid19.pbmg.com.mx/";

////Local - Laragon
//const BASE_URL="http://gdth_api.test/api/";
//const ENVIROMENT_PARAMETROS={ url : "gdth_api.test", tls: false };
//const URL_IMAGEN_BASE_URL="http://gdth_api.test/";

////Local - LocalHost
//const BASE_URL="http://127.0.0.1:8000/api/";
//const ENVIROMENT_PARAMETROS={ url : "127.0.0.1", tls: false };
//const URL_IMAGEN_BASE_URL="http://127.0.0.1:8000/";

////PRODUCCION - Cliker
//const BASE_URL="https://apigdth.cliker.com.mx/api/";
//const ENVIROMENT_PARAMETROS={ url : "apigdth.cliker.com.mx", tls: true };
//const URL_IMAGEN_BASE_URL="https://apigdth.cliker.com.mx/";


export {BASE_URL, ENVIROMENT_PARAMETROS, URL_IMAGEN_BASE_URL}
